<template>
  <v-card class="mx-auto" :loading="task_in_progress">
    <v-toolbar flat color="transparent">
      <v-spacer></v-spacer>
      <v-toolbar-title>EXPORTAR RELATÓRIOS</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-container>
      <v-label>Relatórios</v-label>
      <div class="column_wrapper">
        <v-checkbox
          v-for="(item, k) in items"
          :key="k"
          :value="item.relatorio"
          :label="item.text"
          v-model="relatorios"
        ></v-checkbox>
      </div>
    </v-container>
    <v-divider v-show="$state.role != 'eqpdtiuo'"></v-divider>
    <v-container v-show="$state.role != 'eqpdtiuo'">
      <v-label>Unidades Organizacionais</v-label>
      <div class="column_wrapper">
        <v-checkbox
          v-for="(item, k) in uos"
          :key="k"
          :value="item.id"
          :label="item.sigla"
          v-model="uos_selecionadas"
        ></v-checkbox>
      </div>
    </v-container>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        text
        @click="exportData()"
        :disabled="task_in_progress"
      >
        Download
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { UnidadeOrganizacional, Task, Relatorio } from "@/api/core";
export default {
  data: () => ({
    items: [
      { relatorio: "plano_compras", text: "Plano de Compras" },
      { relatorio: "necessidades", text: "Inventário de Necessidades" },
      { relatorio: "recursos_humanos", text: "Recursos Humanos" },
      { relatorio: "recursos_humanos_geral", text: "Recursos Humanos (Geral)" },
      { relatorio: "plano_metas", text: "Plano de Metas" },
      { relatorio: "plano_acoes", text: "Plano de Ações" },
      { relatorio: "plano_orcamentario", text: "Plano Orçamentário" },
      { relatorio: "resultados_pdtic", text: "Resultados do PDTIC Anterior" },
      { relatorio: "inventario", text: "Inventário" },
      {
        relatorio: "materiais_per_uo",
        text: "Relatório de Materiais por Campus",
      },
      {
        relatorio: "materiais_per_uo_csv",
        text: "Relatório de Materiais por Campus (CSV)",
      },
      {
        relatorio: "plano_orcamentario_geral",
        text: "Plano Orçamentário (Geral)",
      },
      {
        relatorio: "pac",
        text: "Plano Anual de Compras",
      },
    ],
    loading: false,
    search: "",
    relatorios: [],
    uos: [],
    uos_selecionadas: [],
    task_in_progress: false,
  }),

  methods: {
    monitor(task_id) {
      Task.get(task_id)
        .then((response) => {
          this.task_in_progress = true;
          if (response.status == 202) {
            setTimeout(() => this.monitor(task_id), 2000);
          } else {
            this.task_in_progress = false;
            window.location.replace(
              `${process.env.VUE_APP_API_URL}/api/core/download/${response.data.file_id}/`
            );
          }
        })
        .catch((error) => {
          this.task_in_progress = false;
          console.log(error);
        });
    },
    exportData() {
      Relatorio.post({
        relatorios: this.relatorios,
        uos: this.uos_selecionadas,
      })
        .then((response) => {
          this.task_in_progress = true;
          this.monitor(response.data.task_id);
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    UnidadeOrganizacional.list().then((response) => {
      this.uos = response.data;
    });
  },
};
</script>

<style>
.column_wrapper {
  max-height: 400px;
  display: flex;
  flex-flow: column wrap;
}
</style>
